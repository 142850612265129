<template>
  <div class="main-container">
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default">
            <!-- <i
              class="fal fa-user-circle"
              style="margin: 0 10px 0 0; font-size: 18px"
            ></i> -->
            <h2 @click="main()">ລາຍຈ່າຍ</h2>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'company_expense.type'"
          >
            <span v-show="true">/</span>
            <span>add budget</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>
    <div class="page-content">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },

  methods: {
    main() {
      this.$router
        .push({
          name: "company_expense.index",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
</style>
